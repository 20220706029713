import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ProfileService } from './profile.service';
@Injectable()
export class AuthService implements CanActivate {
  constructor(public PS: ProfileService, public router: Router) {}
  	canActivate(): boolean {
		console.log('can invoked');
		var jwt = localStorage.getItem('jwt');
		if(!jwt){
			this.router.navigate(['login']);
		  	return false;
		}else{
			var attempt = this.PS.loadProfilefromStorage();
			if(!attempt){
				this.router.navigate(['/home/login']);
				return false;
			}else{
				return true;
			}
		}
	}
}
